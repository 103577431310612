.row-center {
  align-items: center;
}

img {
  border-radius: 8px;
}

iframe#form {
  border: none;
  height: 530px;
}

.hidden {
  display: none;
}

@media (max-width: 767px){
  iframe#form {
    height: 600px;
  }

}
